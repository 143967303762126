@keyframes myAnim {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.animated-div {
    animation: myAnim 1s ease-out;
}

@keyframes youtube {
	0% {
		opacity: 0;
		transform: rotateX(70deg);
		transform-origin: top;
	}

	100% {
		opacity: 1;
		transform: rotateX(0deg);
		transform-origin: top;
	}
}

.you-tube {
    animation: youtube 1s ease-out;
}

@keyframes celuportada {
	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 0 50%;
	}

	10% {
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-4deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(4deg);
	}

	80% {
		transform: rotate(-2deg);
	}

	90% {
		transform: rotate(2deg);
	}
}

.cel-portada {
    animation: celuportada 1s ease-in-out;
}

